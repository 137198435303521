import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import useCategories from "@/context/categotiesContext";
function AllProducts() {
  // const { products, fetchProducts } = useProducts();
  const [products, setproducts] = useState([]);
  const [deleted, setdeleted] = useState(false);
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false);
  const handleDelete = async (id) => {
    try {
      toast.loading("Deleting product");
      const { data } = await axios.delete(
        `/api/v1/product/${id}`
      );
      toast.dismiss();
      if (data.success) {
        toast.success("Product Deleted Successfulyy");
        setdeleted(true);
      } else {
        toast.error(data.message || "Product Deletion failed");
        setdeleted(false);
      }
    } catch (error) {
      toast.dismiss();
      
        toast.error(error.response.data.message || "Deletion failed ");
     
       
      setdeleted(false);
    }
  };

  const fetchProducts = async () => {
    try {
      setloading(true);
      let url;
      if (selectedCategoryId) {
        url = `/api/v1/products${
          "?categoryId=" + selectedCategoryId
        }`;
      } else {
        url = `/api/v1/products`;
      }
      const response = await fetch(url);

      if (!response.ok) {
        seterror(true);
      }

      const data = await response.json();
      setproducts(data.products);
    } catch (error) {
      seterror(true);
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    null
  );
  const { categories, categoriesLoading } = useCategories();

  const handleCategoryButtonClick = (id) => {
    setSelectedCategoryId(id);
  };

  useEffect(() => {
    fetchProducts();
    if (deleted) {
      setdeleted(false);
    }
    if(error){
      seterror(false);
    }
  }, [deleted, selectedCategoryId]);


  
  return (
    <>
      <div className="h-fit flex flex-wrap my-16 text-white ">
        {!categoriesLoading && (
          <>
            {categories.map((c) => {
              return (
                <button
                  key={c._id}
                  onClick={() => handleCategoryButtonClick(c._id)}
                  className={`category-button-products-page px-5 mx-3 py-3 rounded-2xl my-3 ${
                    selectedCategoryId === c._id
                      ? "bg-red-600 hover:bg-red-500"
                      : "bg-slate-600 hover:bg-slate-500 dark:bg-slate-800 dark:hover:bg-slate-600"
                  }`}
                >
                  {c.name.en}
                </button>
              );
            })}
          </>
        )}
      </div>

      <div className="flex justify-between">

       <p>Total results : {products.length} </p>

        <button
          onClick={() => {
            setSelectedCategoryId("");
          }}
          className="  w-fit underline my-3 mx-4 text-lg hover:scale-105 hover:text-red-600"
        >
          Clear Filters
        </button>
      </div>
      {
        <div className="relative">
          <table className="border-collapse w-full">
            <thead>
              <tr>
                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                  Product ID
                </th>
                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                  Product Name
                </th>
                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                  Category
                </th>
                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="relative">
              {loading ? (
              <tr>
              <td colSpan={4} className="text-center p-3">
                <div className="absolute  top- inset-0 flex items-center justify-center text-3xl left-[50%] -translate-x-[50%] top-[30vh]  dark:bg-slate-900">
                  <span className="text-3xl text-gray-500 dark:text-gray-300">Loading......</span>
                </div>
              </td>
            </tr>
              ) :
               (
                products && (
                  <>
                    {products.length < 1 ? (
                      <div className="absolute   text-3xl left-[50%] -translate-x-[50%] top-[30vh] ">
                        No Products......
                      </div>
                    ) : (
                      products.map((p) => (
                        <tr
                          key={p._id}
                          className="bg-white dark:bg-slate-900 lg:hover:bg-gray-100 dark:hover:bg-slate-800 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                        >
                          <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white text-center border border-b block lg:table-cell relative lg:static">
                            <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                              Product Id
                            </span>
                            {p._id}
                          </td>
                          <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white border border-b text-center block lg:table-cell relative lg:static">
                            <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                              Product Name
                            </span>
                            {p.title.en}
                          </td>

                          <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white border border-b text-center block lg:table-cell relative lg:static">
                            <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                              Product Category
                            </span>
                            {p.category.name.en}
                          </td>
                          <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white border border-b text-center block lg:table-cell relative lg:static">
                            <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                              Actions
                            </span>

                            <button
                              onClick={async () => {
                                await handleDelete(p._id);
                              }}
                              className="text-blue-400 hover:text-blue-600 underline pl-6"
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </>
                )
              )}
            </tbody>
          </table>
        </div>
      }
    </>
  );
}

export default AllProducts;
