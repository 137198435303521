import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
function AboutUsPage() {


  const {t} = useTranslation()

 useEffect(() => {
   
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
 }, [])
 
  return (
    <div className="min-h-screen mt-22">
      <div className="image w-full">
        <img src={"https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/hjveoqajfuorknitmsut"} alt="about-us img" className="h-full w-full" />
      </div>

      <div className="aboutus lg:w-[90%] xl:w-[85%] 2xl:w-[80%] mdd:py-24 md:py-10 py-6  mx-auto space-x-5">
        <div className="about-us-content   md:flex w-full justify-between">
          {/* Part 1 */}
          <div className="space-y-3 p-3 md:px-7 md:py-9 md:w-1/2  mdd:w-[40%] ">
            <h1 className="text-center text-2xl font-seri font-bold  md:text-start">
              {t("ZA-heading")}
            </h1>
            <div className="w-[100%] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF]  to-[#FF2DF7]  my-10 rounded-[100%] opacity-50 " />

            <p>
             {t("about-us-desc")}
            </p>
          </div>
          {/* Part 2 */}
          <div className="min-h-[50vh]  md:w-1/2 mdd:w-[45%]  ">
            <Carousel
              autoPlay
              interval={5000}
              showIndicators={true}
              showStatus={false}
              stopOnHover={false}
              infiniteLoop
              showThumbs={false}
              showArrows={false}
              className="carousel-wrapper  "
            >
              <div className=" p-3 space-y-4">
                <h1 className="capitalize font-bold text-2xl text-center">
                 {t("Vision-Statement-heading")}
                </h1>
                <div className="w-[100%] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF]  to-[#FF2DF7]  my-10 rounded-[100%] opacity-50 " />
                <p className="text-start">
                 {t("Vision-Statement-desc")}
                </p>
              </div>
              <div className="p-3 space-y-4">
                <h1 className="capitalize font-bold text-2xl text-center">
                  {t("Core-Values-heading")}
                </h1>
                <div className="w-[100%] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF]  to-[#FF2DF7]  my-10 rounded-[100%] opacity-50 " />
                <div className="text-start">
                  <ul className="space-y-2">
                    <li>
                      <strong>{t("Core-1-h")}:</strong> {t("Core-1-desc")}
                    </li>
                    <li>
                      <strong>{t("Core-2-h")}:</strong> {t("Core-2-desc")}
                    </li>
                    <li>
                      <strong>{t("Core-3-h")}:</strong> {t("Core-3-desc")}
                    </li>
                    <li>
                      <strong>{t("Core-4-h")}:</strong> {t("Core-4-desc")}
                    </li>
                    <li>
                      <strong>{t("Core-5-h")}:</strong> {t("Core-5-desc")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className=" p-3 min-h-[40vh] space-y-4">
                <h1 className="capitalize font-bold text-2xl text-center">
                 {t("mission-statement-heading")}
                </h1>
                <div className="w-[100%] h-[1px] bg-gradient-to-r from-[#00F0FF] via-[#5200FF]  to-[#FF2DF7]  my-10 rounded-[100%] opacity-50 " />
                <p className="text-start">
                  {t("mission-statement-desc")}
                </p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="separator h-[20vh] w-[70%] mx-auto "></div>
    </div>
  );
}

export default AboutUsPage;
