import { ThemeProvider } from "@/components/theme-provider";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "./index.css";

export default App;

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";



createRoot(document.getElementById("root")).render(
  
    <I18nextProvider i18n={i18n}>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <App />
      </ThemeProvider>
    </I18nextProvider>
  
);
