import { useEffect } from "react"
function DashboardHome() {


  useEffect(()=>{

    window.scroll({
      top:0,
      behavior:"smooth"
    })
  },[])

  return (
    <div className='h-full w-full text-4xl flex justify-center items-center'>Dashboard</div>
  )
}

export default DashboardHome