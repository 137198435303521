import { Skeleton } from "@/components/ui/skeleton";
import useCategories from "@/context/categotiesContext";
import useMyLanguage from "@/context/languageContext";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { t } from "i18next";
import { Link } from "react-router-dom";

function Industries() {
  const { categories, categoriesLoading } = useCategories();
  const { language } = useMyLanguage();

  useGSAP(() => {
    if (!categoriesLoading) {
      if (window.innerWidth < 768) {
        const cards = document.querySelectorAll(".home-page-industries-cards");
        cards.forEach((card) => {
          gsap.from(card, {
            y: "20%",
            x: "20",
            opacity: 0,
            scrollTrigger: {
              trigger: card,
              scroller: "body",
              start: "top 80%",
              end: "top top",
            },
          });
        });
      } else {
        gsap.from(".home-page-industries-cards", {
          x: "100%",
          y: "20%",
          opacity: 0,
          stagger: 0.2,
          duration: 0.7,
          scrollTrigger: {
            scroller: "body",
            trigger: "#home-page-industries",
            start: "top 45%",
          },
        });
      }
    }
  }, [categoriesLoading]);

  return (
    <div id="home-page-industries" className="  pt-4 pb-20">
      <h1 className="text-center bg-slate-300  dark:bg-slate-700 text-slate-600 py-4 text-4xl font-bold uppercase dark:text-slate-100 shadow-xl ">
        Industries
      </h1>

      {categoriesLoading === true ? (
        <>
          <div className="mt-20 p-12 flex flex-wrap justify-center gap-5   ">
            <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
              <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
              <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
            </div>
            <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
              <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
              <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
            </div>{" "}
            <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
              <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
              <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
            </div>{" "}
            <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
              <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
              <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <>
            {categories.length === 0 ? (
              <div className="h-screen flex justify-center items-center text-3xl mdd:text-5xl font-bold text-gray-300 dark:text-gray-700">
                No Industry Found
              </div>
            ) : (
              <div
                id="industries-div"
                className="mt-6 mdd:max-h-[570px] max-h-[1620px] duration-150 transition-all  overflow-hidden p-4 flex flex-wrap justify-center space-x-2 "
                style={{transition: "height 0.5s ease"}}
              >
                {categories.map((c) => (
                  // Products
                  <div
                    onMouseEnter={() => {
                      const img1 = document.getElementById(`img1-${c._id}`);
                      const img2 = document.getElementById(`img2-${c._id}`);

                      if (img1 && img2) {
                        img1.style.opacity = "0"; // Full opacity
                        img2.style.opacity = "1"; // Hidden (0 opacity)
                      }
                    }}
                    onMouseLeave={() => {
                      const img1 = document.getElementById(`img1-${c._id}`);
                      const img2 = document.getElementById(`img2-${c._id}`);

                      if (img1 && img2) {
                        img1.style.opacity = "1"; // Full opacity
                        img2.style.opacity = "0"; // Hidden (0 opacity)
                      }
                    }}
                    className="relative home-page-industries-cards shadow-lg shadow-black/75 dark:shadow-slate-800/75 my-4   w-full max-w-[430px]  sm:w-[400px] md:w-[450px] min-h-[500px] mdd:min-h-[530px] group border-2 border-[#dadada] dark:border-slate-800  rounded-lg overflow-hidden  pb-4  flex flex-col items-center justify-center "
                    key={c._id}
                  >
                    <div className="absolute pl-8 text-white top-0 left-0 upper-part-category bg-black/70 z-10  w-full text-lg  font-bold py-4  uppercase ">
                      {c.name[language]}
                    </div>

                    <div className="absolute   bottom-0 left-0 top-0 h-full w-full flex items-center justify-center image-category">
                      <img
                        loading="lazy"
                        src={c.image.url}
                        alt={c.name.en}
                        id={`img1-${c._id}`}
                        className="absolute transition-all duration-500  w-full h-full"
                      />
                      <img
                        loading="lazy"
                        src={c.image.url}
                        alt={c.name.en}
                        id={`img2-${c._id}`}
                        className=" absolute transition-all duration-500 opacity-0 scale-110 w-full h-full"
                      />
                    </div>
                    <Link
                      to={`/products?categoryid=${c._id}`}
                      className="absolute text-white  down-part-category cursor-pointer gap-2 hover:gap-3 transition-all duration-200  flex bottom-0 left-0 text-lg    bg-black/70 z-10  w-full pl-8 py-4"
                    >            
                      <div className="underline">{t("View-Products")}</div>{" "}
                      <div className="!no-underline">{"->"}</div>
                    </Link>
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-center pb-5 pt-10">
              <button
                onClick={() => {
                  const divv = document.querySelector("#industries-div");
                  const button = document.querySelector("#view-all-button");

                  if (button.innerText === "View All") {
                    divv.classList.remove("mdd:max-h-[570px]");
                    divv.classList.remove("max-h-[1620px]");
                    button.innerText = "View Less";
                  } else {
                    divv.classList.add("mdd:max-h-[570px]");
                    divv.classList.add("max-h-[1620px]");
                    button.innerText = "View All";
                  }
                }}
                id="view-all-button"
                className="text-2xl font-semibold hover:font-bold hover:text-red-600 underline w-fit cursor-pointer"
              >
                View All
              </button>
            </div>
          </>
        </>
      )}
    </div>
  );
}

export default Industries;