import useProductDetails from "@/context/productDetailsContext";
import { t } from "i18next";

function Download() {
  const { product, loading } = useProductDetails();
 
  return (
    <>
      {!loading && (
        <div id="download-box-details"  className=" px-12 py-9 space-y-6">
          {" "}
          <>
            <h1 className="text-2xl font-bold my-2 uppercase">{t("Download-Brochure" )}</h1>
            {
              product.brochure.url ? 
              <a
              href={product.brochure.url}
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-lg "
            >
              {t("Download-Now")}
            </a> : 
            <h2>
              Brocher Not available
            </h2>
            }
          </>
        </div>
      )}
    </>
  );
}

export default Download;
