import { createContext, useContext } from "react";

export const meContext = createContext({
  me: {},
  loading: false,
  isAuthenticated:false,
  fetchMe : async ()=>{},
  
});


export const MeContextProvider = meContext.Provider;

export default function useMe() {
  return useContext(meContext);
}
