import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/layout/NavBar/NavBar";
import Footer from "./components/layout/footer/Footer";
import { LanguagesContextProvider } from "./context/languageContext.js";
import { MeContextProvider } from "./context/meContext.js";
import Home from "./pages/Home";
import AboutUsPage from "./pages/aboutUsPage/AboutUsPage";
import ContactPage from "./pages/contactPage/ContactPage";
import HeadOffice from "./pages/contactPage/HeadOffice";

import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./pages/loginPage/LoginPage.jsx";
// import ProtectedRoute from "./ProtectedRoute.jsx";
import axios from "axios";
import ProtectedRoute from "./ProtectedRoute.jsx";
import { CategoriesContextProvider } from "./context/categotiesContext.js";
import AddCategory from "./pages/admin/categories/AddCategory";
import AdminCategories from "./pages/admin/categories/AdminCategories";
import AllCategories from "./pages/admin/categories/AllCategories.jsx";
import AdminDashboard from "./pages/admin/dashboard/AdminDashboard.jsx";
import DashboardHome from "./pages/admin/dashboard/DashboardHome.jsx";
import AddProduct from "./pages/admin/products/AddProduct.jsx";
import AdminProducts from "./pages/admin/products/AdminProducts.jsx";
import AllProducts from "./pages/admin/products/AllProducts.jsx";
import AddUser from "./pages/admin/users/AddUser.jsx";
import AdminUsers from "./pages/admin/users/AdminUsers.jsx";
import AllUsers from "./pages/admin/users/AllUsers.jsx";
import CategoryImage from "./pages/productPage/CategoryImage.jsx";
import ProductDetailsPage from "./pages/productPage/ProductDetailsPage.jsx";
import ProductsPage from "./pages/productPage/ProductsPage.jsx";
import Applications from "./pages/productPage/outlet/Applications.jsx";
import Download from "./pages/productPage/outlet/Download.jsx";
import Specifications from "./pages/productPage/outlet/Specifications.jsx";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import Adminmessages from "./pages/admin/messages/Adminmessages";
export default function App() {

  gsap.registerPlugin(useGSAP);
  gsap.registerPlugin(ScrollTrigger);


  const [me, setMe] = useState([]);
  const [meLoading, setmeLoading] = useState(true);
  const [isAuthenticated, setisAuthenticated] = useState(false);

  const [categories, setcategories] = useState([]);
  const [categoriesLoading, setcategoriesLoading] = useState(false);
  const [error, setError] = useState(false);
  const [language, setlanguage] = useState(
    "en"
  );
  const fetchCategories = async () => {
    try {
      setcategoriesLoading(true);
      const url = `/api/v1/category`;
      const response = await fetch(url);

      const data = await response.json();

      if (data.success) {
        setcategories(data.categories);
      } else {
        console.log("fetching categories error");
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setcategoriesLoading(false);
    }
  };

  
  const fetchMe = async () => {
    try {
      setmeLoading(true);
      const url = `/api/v1/me`;

      // Perform the request using axios
      const response = await axios.get(url, { withCredentials: true });

      const data = response.data;

      if (data.success) {
        setMe(data.admin);
        setisAuthenticated(true);
      } else {
        setisAuthenticated(false);
      }
    } catch (error) {
      console.log(error);
      setisAuthenticated(false);
    } finally {
      setmeLoading(false);
    }
  };

  useEffect(() => {
    fetchMe();
    fetchCategories();
    if (error) {
      setError(false);
    }
  }, [isAuthenticated]); // Empty dependency array to run only once

  return (
    <>
      <MeContextProvider
        value={{
          me: me,

          isAuthenticated,
          loading: meLoading,

          fetchMe,
        }}
      >
        <LanguagesContextProvider
          value={{
            language,
            changeLanguage: (ln) => {
              setlanguage(ln);
            },
          }}
        >
          <CategoriesContextProvider
            value={{ categories, categoriesLoading, fetchCategories }}
          >
            <div className=" max-w-screen overflow-hidden  ">
              <BrowserRouter>
                <NavBar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about-us" element={<AboutUsPage />} />
                  <Route path="/admin/login" element={<LoginPage />} />
                  <Route path="/products" element={<ProductsPage />}>
                    <Route path="" element={<CategoryImage />} />
                    <Route path="details" element={<ProductDetailsPage />}>
                      <Route path="" element={<Specifications />} />
                      <Route path="applications" element={<Applications />} />
                      <Route path="downloads" element={<Download />} />
                    </Route>
                  </Route>

                  {/* Route */}

                  {/* Route */}
                  <Route path="/contact-us" element={<ContactPage />}>
                    <Route path="" element={<HeadOffice />} />
                    <Route path="factory-address" element={<HeadOffice />} />
                    <Route path="sales-office" element={<HeadOffice />} />
                    <Route path="location" element={<HeadOffice />} />
                  </Route>
                  {/* Route */}

                  <Route
                    path="/admin/dashboard"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated}
                        loading={meLoading}
                      >
                        <AdminDashboard />
                      </ProtectedRoute>
                    }
                  >
                    <Route path="" element={<DashboardHome />} />
                    {/* SubRoute */}
                    <Route
                      path="products"
                      element={
                        <ProtectedRoute
                          isAuthenticated={isAuthenticated}
                          loading={meLoading}
                        >
                          <AdminProducts />
                        </ProtectedRoute>
                      }
                    >
                      <Route path="" element={<AllProducts />} />
                      <Route
                        path="upload"
                        element={
                          <ProtectedRoute
                            isAuthenticated={isAuthenticated}
                            loading={meLoading}
                          >
                            <AddProduct />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    {/* Sub Route */}
                    <Route
                      path="categories"
                      element={
                        <ProtectedRoute
                          isAuthenticated={isAuthenticated}
                          loading={meLoading}
                        >
                          <AdminCategories />
                        </ProtectedRoute>
                      }
                    >
                      <Route path="" element={<AllCategories />} />
                      <Route
                        path="upload"
                        element={
                          <ProtectedRoute
                            isAuthenticated={isAuthenticated}
                            loading={meLoading}
                          >
                            <AddCategory />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route
                    path="messages"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated}
                        loading={meLoading}
                      >
                        <Adminmessages />
                      </ProtectedRoute>
                    }
                  />
                    {/* Sub Route */}
                    <Route
                    path="users"
                    element={
                      <ProtectedRoute
                        isAuthenticated={isAuthenticated}
                        loading={meLoading}
                      >
                        <AdminUsers />
                      </ProtectedRoute>
                    }
                  >
                    <Route  path="" element={<AllUsers />} />
                    <Route  path="upload" element={<AddUser />} />
                  </Route>
                  </Route>
                </Routes>

                {/* <>
          <a
            href="https://res.cloudinary.com/dnhzqlits/image/upload/v1724247667/lkpnr70hjrdun4hkeks2.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hell
          </a>
        </> */}
                <ToastContainer />
                <Footer />
              </BrowserRouter>
            </div>
          </CategoriesContextProvider>
        </LanguagesContextProvider>
      </MeContextProvider>
    </>
  );
}
