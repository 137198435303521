import { Button } from "@/components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { Skeleton } from "@/components/ui/skeleton";
import useMyLanguage from "@/context/languageContext";
import { ProductDetailsContextProvider } from "@/context/productDetailsContext";
import { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

import { toast } from "react-toastify";
import axios from "axios";
import { t } from "i18next";
function ProductDetailsPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("categoryid");
  const p_id = query.get("p_id");
  const currentQueryParams = location.search;
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [telephone, settelephone] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");

  const sendMessage = async (formdata) => {
    try {
      toast.loading("Sending message to admin.");
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `/api/v1/messages`;

      const { data } = await axios.post(url, formdata, config);

      if (data.success) {
        toast.dismiss();
        toast.success("Message have been send.");
        setemail("");
        setname("");

        setmessage("");
        settelephone("");
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Send message failed ");
    }
  };

  // Access the current URL
  const currentUrl = window.location.href;
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append(
      "message",
      `${message} <div>For Product: <strong><a href="${currentUrl}">${product.title.en}</a></strong></div>`
    );

    formData.append("telephone", telephone);
    // formData.append("postcode", code);

    await sendMessage(formData);
  };

  const fetchProduct = async () => {
    try {
      setLoading(true);
      const url = `/api/v1/product${"/" + p_id}`;

      const response = await fetch(url);
      const data = await response.json();
      if (!response.ok) {
        toast.error("Product Not Found!");
        navigate(`/`);
        return;
      }
      setProduct(data.product);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const { language } = useMyLanguage();
  useEffect(() => {
    fetchProduct();
    window.scrollTo({
      top:0,
      behavior:"smooth"
    });
  }, [p_id,id]);

  if (loading) {
    return (
      <div className="mobile w-[93%] mdd:flex h-screen mdd:h-[70vh] justify-between sm:w-[85%] md:w-[80%] mdd:w-[85%] lg:w-[80%] mx-auto xl:w-[75%]   ">
        <Skeleton className="h-[50%] mdd:h-full w-full mdd:w-1/2" />

        <div className="h-1/2 py-3 flex mdd:h-full flex-col justify-between mdd:w-1/2 p-5">
          <Skeleton className="h-[10%] w-full " />
          <Skeleton className="h-[88%] " />
        </div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="h-screen  text-slate-400 font-bold w-full text-4xl flex items-center justify-center ">
        Product not found.
      </div>
    );
  }

  return (
    <ProductDetailsContextProvider value={{ loading, product }}>
      <div className="w-full ">
        <div className="product-heading-bar py-3 mdd:py-9 min-h-fit bg-[#333333] dark:bg-[#212121] text-white flex items-center justify-evenly">
          <h1 className="Product-Title  mdd:text-start text-4xl w-1/2 text-center font-bold  ">
            {product.title[language]}{" "}
          </h1>

          <Drawer className={"max-h-[70vh"}>
            <DrawerTrigger className="bg-red-700 h-fit px-4 py-2 mdd:py-3 mdd:px-6 rounded-full ">
              {t("Equip-Now")}
            </DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Enter Your Details?</DrawerTitle>
                <DrawerDescription>
                  The details will send to one of our admins.
                </DrawerDescription>
              </DrawerHeader>

              <form
                onSubmit={handleFormSubmit}
                className="mx-auto  max-w-xl max-h-[60vh]  w-[90%] "
              >
                <div className="w-full">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block w-full text-sm font-semibold leading-6 "
                    >
                      Name
                    </label>
                    <div className="mt-2.5">
                      <input
                        required
                        id="first-name"
                        name="first-name"
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setname(e.target.value);
                        }}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="Telephone"
                      className="block text-sm font-semibold leading-6 "
                    >
                      Telephone
                    </label>
                    <div className="mt-2.5">
                      <input
                        required
                        id="Telephone"
                        name="Telephone"
                        type="tel"
                        value={telephone}
                        onChange={(e) => {
                          settelephone(e.target.value);
                        }}
                        autoComplete="organization"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold leading-6 "
                    >
                      Email
                    </label>
                    <div className="mt-2.5">
                      <input
                        required
                        id="email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                        autoComplete="email"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="message"
                      className="block text-sm font-semibold leading-6 "
                    >
                      Message
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        required
                        value={message}
                        onChange={(e) => {
                          setmessage(e.target.value);
                        }}
                        id="message"
                        name="message"
                        rows={4}
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <button
                    type="submit"
                    className="block w-full rounded-md bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Send
                  </button>
                </div>
              </form>

              <DrawerFooter>
                <DrawerClose>
                  <Button variant="outline">Cancel</Button>
                </DrawerClose>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>

        <div className="mobile w-[93%] sm:w-[85%] md:w-[80%] mdd:w-[85%] lg:w-[80%] mx-auto xl:w-[75%]   ">
          <div className="links flex justify-end text-xl space-x-1 my-3 lg:text-2xl">
            <Link className="hover:underline hover:text-red-500" to={"/"}>
              {t("home")}
            </Link>{" "}
            <span className="text-red-800">{" / "}</span>
            <Link
              className="hover:underline hover:text-red-500"
              to={`/products/${"?categoryid=" + id}`}
            >
              {t("Category")}
            </Link>{" "}
            <span className="text-red-800">{" / "}</span>
            <Link
              className="hover:underline hover:text-red-500"
              to={`/products/details${"?categoryid=" + id}${"&p_id=" + p_id}`}
            >
              {t("Product")}
            </Link>
          </div>
          <div
            className="product-Details-with-image shadow-xl min-h-[700px] my-14 shadow-slate-200 
        dark:shadow-slate-900  mdd:flex justify-between"
          >
            <div className="mdd:w-1/2 lg:w-[40%]">
              <Carousel
                showIndicators={true}
                showStatus={false}
                stopOnHover={true}
                infiniteLoop
                showThumbs={true}
                showArrows={false}
                className="carousel-wrapper"
              >
                {product.images
                  ?.filter((_, idx) => idx !== 0) // Filter out the first image
                  .map((img, idx) => (
                    <img
                      key={idx}
                      src={img?.url || ""}
                      alt={img?.url || "Product image"}
                      className="h-full w-full rounded-md"
                    />
                  ))}
              </Carousel>
            </div>

            {/* Desktop */}
            <div className="outlet-details hidden mdd:block mdd:w-1/2">
              <div className="outlet-navlinks flex  lg:w-[80%]  ">
                <NavLink
                  end
                  to={`.${currentQueryParams}`}
                  className={({ isActive }) =>
                    `flex-1 py-2 mx-[1px] text-center ${
                      isActive
                        ? "bg-red-500  text-white"
                        : "bg-slate-100 dark:bg-slate-800"
                    }`
                  }
                >
                  {t("Specifications")}
                </NavLink>
                <NavLink
                  to={`applications${currentQueryParams}`}
                  className={({ isActive }) =>
                    `flex-1 py-2 mx-[1px] text-center ${
                      isActive
                        ? "bg-red-500  text-white"
                        : "bg-slate-100 dark:bg-slate-800"
                    }`
                  }
                >
                  {t("Applications")}
                </NavLink>
                <NavLink
                  to={`downloads${currentQueryParams}`}
                  className={({ isActive }) =>
                    `flex-1 py-2 mx-[1px] text-center ${
                      isActive
                        ? "bg-red-500  text-white"
                        : "bg-slate-100 dark:bg-slate-800"
                    }`
                  }
                >
                  {t("Download")}
                </NavLink>
              </div>

              <Outlet />
            </div>

            {/* Mopbile Details */}
            <div className="details-of-product mdd:hidden py-10 px-4 ">
              <div className="Specifications-mob space-y-2 my-4">
                <h1 className="text-2xl font-bold  uppercase">
                  {t("Specifications")}
                </h1>
                <ul className="pl-6 list-disc">
                  {product.specifications[language].map((s, index) => (
                    <li key={index}>{s}</li>
                  ))}
                </ul>
              </div>
              <div className="Properties-mob space-y-2 my-4">
                <h1 className="text-2xl font-bold  uppercase">{t("Properties")}</h1>
                <ul className="pl-6 list-disc">
                  {product.properties[language].map((s, index) => (
                    <li key={index}>{s}</li>
                  ))}
                </ul>
              </div>
              <div className="Applications-mob space-y-2 my-4">
                <h1 className="text-2xl font-bold  uppercase">{t("Applications")} </h1>
                <ul className="pl-6 list-disc">
                  {product.applications[language].map((s, index) => (
                    <li key={index}>{s}</li>
                  ))}
                </ul>
              </div>
              <div className="Brochure-mob space-y-2 my-4">
                <h1 className="text-2xl font-bold  uppercase  ">
                  {t("Download-Brochure")}
                </h1>

                <a
                  href={product.brochure.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-lg "
                >
                  {t("Download-Now")}
                </a>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </ProductDetailsContextProvider>
  );
}

export default ProductDetailsPage;
