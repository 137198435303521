import useMyLanguage from "@/context/languageContext";
import useProductDetails from "@/context/productDetailsContext";

function Specifications() {
  const { product, loading } = useProductDetails();
  const { language } = useMyLanguage();
  return (
    <>
      {!loading && (
        <div id="specs-box-details" className=" px-6 py-9 space-y-6">
          {" "}
          <>
            {product.specifications[language].length === 0 ? (
              <>
                <div className="pl-6 text-xl">No Specifications</div>
              </>
            ) : (
              <>
                <ul className=" list-disc">
                  {product.specifications[language].map((s, index) => (
                    <li key={index}>{s}</li>
                  ))}
                </ul>
              </>
            )}
          </>
          <>
            <div className="applications-desktop">
            <h1 className="text-2xl font-bold  uppercase">
                  Properties
                </h1>
              {product.properties[language].length === 0 ? (
                <>
                  <div className="pl-6 text-xl">No Properties</div>
                </>
              ) : (
                <>
                  <ul className=" list-disc">
                    {product.properties[language].map((s, index) => (
                      <li key={index}>{s}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default Specifications;
