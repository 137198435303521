import useCategories from "@/context/categotiesContext";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

function AddCategory() {
  const [name, setname] = useState({
    en: "",
    ch: "",
    fr: "",
    ge: "",
    ru: "",
  });

  const [imagePreview, setimagePreview] = useState("");
  const [coverImagePreview, setcoverImagePreview] = useState("");

  const [image, setimage] = useState(null);
  const [coverImage, setcoverImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files?.[0]; // Get the first (and only) file selected

    if (file) {
      setimage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setimagePreview(reader.result );
      };
      reader.readAsDataURL(file);
    } else {
      toast.info("No Image selected.");
    }
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files?.[0]; // Get the first (and only) file selected

    if (file) {
      setcoverImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setcoverImagePreview(reader.result );
      };
      reader.readAsDataURL(file);
    } else {
      toast.info("No cover image selected.");
    }
  };
const { fetchCategories} = useCategories()
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", JSON.stringify(name));
    formData.append("categoryImage", image );
    formData.append("categoryCoverImage", coverImage);

    toast.loading("Category creating....");
    try {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      const url = `/api/v1/category`;
      await axios.post(url, formData, config);
      toast.dismiss();

      toast.success("Category have been created.");
      setimage(null);
      setcoverImage(null);
      setimagePreview("");
      setcoverImagePreview("");
      setname({
        ch: "",
        en: "",
        ge: "",
        fr: "",
        ru: "",
      });

      fetchCategories();

    } catch (error) {
      toast.dismiss();

      toast.error(error?.response?.data?.message || "Something went wrong");
    }
  };

  return (
    <div className="mt-9 ">
      <form action="" onSubmit={handleFormSubmit}>
        <div className="w-[95%] mx-auto ">
          {/* Category Name  */}
          <div className="Category-name ">
            <div className="label-Category-name text-center my-7">
              <label className="text-xl underline underline-offset-8 ">
                Category Name
              </label>
            </div>

            <div className="flex flex-wrap gap-4">
              <div>
                <p className="mb-4 font-bold text-xl">English</p>
                <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={name.en}
                    onChange={(e) => {
                      setname({ ...name, en: e.target.value });
                    }}
                    required
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Category Name
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Chineese</p>
                <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={name.ch}
                    onChange={(e) => {
                      setname({ ...name, ch: e.target.value });
                    }}
                    required
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Category Name
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">French</p>
                <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={name.fr}
                    onChange={(e) => {
                      setname({ ...name, fr: e.target.value });
                    }}
                    required
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Category Name
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">German</p>
                <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={name.ge}
                    onChange={(e) => {
                      setname({ ...name, ge: e.target.value });
                    }}
                    required
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Category Name
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Russian</p>
                <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={name.ru}
                    onChange={(e) => {
                      setname({ ...name, ru: e.target.value });
                    }}
                    required
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Category Name
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="images-container py-7">
          <div className="label-productImages text-center mb-8">
            <label className="text-xl underline underline-offset-8 ">
              Add {"Category's"} Image
            </label>
          </div>
          <input
            type="file"
            name="productImages"
            id=""
            required
            accept="image/*"
            max={1}
            onChange={handleFileChange}
          />

          <div className="flex flex-wrap my-4 ">
            {imagePreview && (
              <img
                src={`${imagePreview}`}
                alt={`Preview Image`}
                className="w-20 mx-1"
              />
            )}
          </div>
        </div>

        <div className="images-container py-7">
          <div className="label-productImages text-center mb-8">
            <label className="text-xl underline underline-offset-8 ">
              Add {"Category's"} Cover Image
            </label>
          </div>
          <input
            type="file"
            name="productImages"
            id=""
            required
            accept="image/*"
            max={1}
            onChange={handleCoverImageChange}
          />

          <div className="flex flex-wrap my-4 ">
            {coverImagePreview && (
              <img
                src={`${coverImagePreview}`}
                alt={`Preview Image`}
                className="w-20 mx-1"
              />
            )}
          </div>
        </div>
        <div className="add-btn flex justify-center mt-9 mb-11">
          <button
            type="submit"
            className="mx-auto bg-[#0285AD] px-8 py-4 rounded-full text-white "
          >
            Add Category
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCategory;
