import useCategories from "@/context/categotiesContext";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function AllCategories() {
  const { categoriesLoading, categories, fetchCategories } = useCategories();
  const [deleted, setdeleted] = useState(false);
  const [error, seterror] = useState(false);
  const handleDelete = async (id) => {
    try {
      toast.loading("Deleting Category");
      const { data } = await axios.delete(
        `/api/v1/category/${id}`
      );
      toast.dismiss();
      if (data.success) {
        toast.success("Category Deleted Successfulyy");
        setdeleted(true);
      } else {
        toast.error(data.message || "Category Deletion failed");
        setdeleted(false);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message || "Deletion failed ");
      setdeleted(false);
    }
  };

  useEffect(() => {
    fetchCategories();

    if (deleted) {
      setdeleted(false);
    }
    if (error) {
      seterror(false);
    }
  }, [deleted, error]);

  return (
    <>
      {
        <div className="relative">
          <table className="border-collapse w-full">
            <thead>
              <tr>
                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                  Category ID
                </th>
                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                  Category Name
                </th>
                <th className="p-3 font-bold uppercase bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="relative">
              {categoriesLoading ? (
                <tr>
                  <td colSpan={4} className="text-center p-3">
                    <div className="absolute  top- inset-0 flex items-center justify-center text-3xl left-[50%] -translate-x-[50%] top-[30vh]  dark:bg-slate-900">
                      <span className="text-3xl text-gray-500 dark:text-gray-300">
                        Loading......
                      </span>
                    </div>
                  </td>
                </tr>
              ) : (
                categories && (
                  <>
                    {categories.length < 1 ? (
                      <div className="absolute   text-3xl left-[50%] -translate-x-[50%] top-[30vh] ">
                        No Categories......
                      </div>
                    ) : (
                      categories.map(
                        (c) => (
                          <tr
                            key={c._id}
                            className="bg-white dark:bg-slate-900 lg:hover:bg-gray-100 dark:hover:bg-slate-800 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
                          >
                            <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white text-center border border-b block lg:table-cell relative lg:static">
                              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                                Category Id
                              </span>
                              {c._id}
                            </td>
                            <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white border border-b text-center block lg:table-cell relative lg:static">
                              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                                Category Name
                              </span>
                              {c.name.en}
                            </td>

                            {/* <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white border border-b text-center block lg:table-cell relative lg:static">
                          <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                            Product Category
                          </span>
                          {p.category.name.en}
                        </td> */}
                            <td className="w-full lg:w-auto p-3 text-gray-800 dark:text-white border border-b text-center block lg:table-cell relative lg:static">
                              <span className="lg:hidden absolute top-0 left-0 bg-blue-200 dark:bg-slate-600 px-2 py-1 text-xs font-bold uppercase">
                                Actions
                              </span>

                              <button
                                onClick={() => {
                                  handleDelete(c._id);
                                }}
                                className="text-blue-400 hover:text-blue-600 underline pl-6"
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </>
                )
              )}
            </tbody>
          </table>
        </div>
      }
    </>
  );
}

export default AllCategories;
