function HeadOffice() {
  return (
    <div className=" space-y-3 md:m-6 p-3">
      <div className="flex">
        <a
          href="https://maps.app.goo.gl/MAgpV4g15SqE2WwH9?g_st=com.google.maps.preview.copy"
          className="flex hover:underline  space-x-4 items-center"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            className="w-8 h-8 text-white bg-red-600 p-[2px]"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>
          <p>Head office Address: 56-Main Road Samanabad Lahore</p>
        </a>
      </div>

      <div className="flex  ">
        <a
          href="https://wa.me/923324592203"
          className="flex hover:underline  space-x-4 items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
            className="w-8 h-8 bg-red-600 p-[4px] text-white"
          >
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p>+92 332 4592203</p>
        </a>
      </div>

      <div className="flex">
        <a
          href="mailto:info@zafarazfar.com"
          className="flex hover:underline space-x-4 items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            aria-hidden="true"
            className="w-8 h-8 p-2 bg-red-600 text-white"
          >
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"></path>
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"></path>
          </svg>
          <p>info@zafarazfar.com</p>
        </a>
      </div>
    </div>
  );
}

export default HeadOffice;
