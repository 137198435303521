import AboutUs from './aboutUsPage/AboutUs'
import Industries from './landingPage/Industries/Industries'
import LandingPage from './landingPage/LandingPage'

function Home() {
  return (
   <>
    <LandingPage/>
    <Industries/>
    <AboutUs/>

   </>
  )
}

export default Home