import { createContext, useContext } from "react";

export const productDetailsContext = createContext({
  product:{},
  loading: false,

  
});


export const ProductDetailsContextProvider = productDetailsContext.Provider;

export default function useProductDetails() {
  return useContext(productDetailsContext);
}
