import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useCategories from "@/context/categotiesContext";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
function AddProduct() {
  const [title, settitle] = useState({
    en: "",
    ch: "",
    fr: "",
    ge: "",
    ru: "",
  });
  const [spec, setspec] = useState({
    en: "",
    ch: "",
    fr: "",
    ge: "",
    ru: "",
  });

  const [specs, setspecs] = useState({
    en: [],
    ch: [],
    fr: [],
    ge: [],
    ru: [],
  });

  const [prop, setprop] = useState({
    en: "",
    ch: "",
    fr: "",
    ge: "",
    ru: "",
  });

  const [propss, setprops] = useState({
    en: [],
    ch: [],
    fr: [],
    ge: [],
    ru: [],
  });

  const [app, setapp] = useState({
    en: "",
    ch: "",
    fr: "",
    ge: "",
    ru: "",
  });

  const [apps, setapps] = useState({
    en: [],
    ch: [],
    fr: [],
    ge: [],
    ru: [],
  });

  const [category, setcategory] = useState("");
  const [broucher, setbroucher] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [success, setsuccess] = useState(false);
  const [postProductRequestError, setpostProductRequestError] = useState(false);
  const [message, setmessage] = useState("");

  const { categories } = useCategories();

  const handleSpecificationSubmit = () => {
    if (spec.en && spec.ch && spec.fr && spec.ge && spec.ru) {
      const newSpecs = {
        en: [...specs.en, spec.en],
        ch: [...specs.ch, spec.ch],
        fr: [...specs.fr, spec.fr],
        ge: [...specs.ge, spec.ge],
        ru: [...specs.ru, spec.ru],
      };

      setspecs(newSpecs);
      setspec({
        en: "",
        ch: "",
        fr: "",
        ge: "",
        ru: "",
      });
    } else {
      toast.error("Enter specs in all Languages");
    }
  };

  const handlePropertiesSubmit = () => {
    if (prop.en && prop.ch && prop.fr && prop.ge && prop.ru) {
      const newprops = {
        en: [...propss.en, prop.en],
        ch: [...propss.ch, prop.ch],
        fr: [...propss.fr, prop.fr],
        ge: [...propss.ge, prop.ge],
        ru: [...propss.ru, prop.ru],
      };

      setprops(newprops);
      setprop({
        en: "",
        ch: "",
        fr: "",
        ge: "",
        ru: "",
      });
    } else {
      toast.error("Enter Properties in all Languages");
    }
  };

  const handleApplicationsSubmit = () => {
    if (app.en && app.ch && app.fr && app.ge && app.ru) {
      const newapps = {
        en: [...apps.en, app.en],
        ch: [...apps.ch, app.ch],
        fr: [...apps.fr, app.fr],
        ge: [...apps.ge, app.ge],
        ru: [...apps.ru, app.ru],
      };

      setapps(newapps);
      setapp({
        en: "",
        ch: "",
        fr: "",
        ge: "",
        ru: "",
      });
    } else {
      toast.error("Enter Applications in all Languages");
    }
  };

  const removeSpec = (index) => {
    setspecs((prevSpecs) => ({
      en: prevSpecs.en.filter((_, i) => i !== index),
      ch: prevSpecs.ch.filter((_, i) => i !== index),
      fr: prevSpecs.fr.filter((_, i) => i !== index),
      ge: prevSpecs.ge.filter((_, i) => i !== index),
      ru: prevSpecs.ru.filter((_, i) => i !== index),
    }));
  };

  const removeProp = (index) => {
    setprops((prevprops) => ({
      en: prevprops.en.filter((_, i) => i !== index),
      ch: prevprops.ch.filter((_, i) => i !== index),
      fr: prevprops.fr.filter((_, i) => i !== index),
      ge: prevprops.ge.filter((_, i) => i !== index),
      ru: prevprops.ru.filter((_, i) => i !== index),
    }));
  };

  const removeApp = (index) => {
    setapps((prevapps) => ({
      en: prevapps.en.filter((_, i) => i !== index),
      ch: prevapps.ch.filter((_, i) => i !== index),
      fr: prevapps.fr.filter((_, i) => i !== index),
      ge: prevapps.ge.filter((_, i) => i !== index),
      ru: prevapps.ru.filter((_, i) => i !== index),
    }));
  };

  const handleCategoryChange = (c_id) => {
    setcategory(c_id);
  };

  const handleBrochureFileChange = (e) => {
    const file = e.target.files?.[0]; // Get the first (and only) file selected

    if (file) {
      setbroucher(file);
    } else {
      toast.info("No file selected.");
    }
  };

  const postProductRequest = async (formData) => {
    toast.loading("Product creating....");
    try {
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const url = `/api/v1/products`;
      await axios.post(url, formData, config);
      toast.dismiss();
      setmessage("Product have been added . ");
      setImages([]);
      setImagesPreview([]);

      settitle({
        ch: "",
        en: "",
        ge: "",
        fr: "",
        ru: "",
      });
      setbroucher(null);
      setspecs({
        ch: [],
        en: [],
        ge: [],
        fr: [],
        ru: [],
      });
      setapps({
        ch: [],
        en: [],
        ge: [],
        fr: [],
        ru: [],
      });
      setprops({
        ch: [],
        en: [],
        ge: [],
        fr: [],
        ru: [],
      });

      setsuccess(true);
    } catch (error) {

      toast.dismiss();
      setpostProductRequestError(true);
      console.log(error)
      setmessage(error.response.data.message || "Something went wrong");
      setsuccess(false);

    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const anyBlank = Object.values(title).some((value) => value.trim() === "");

    if (anyBlank || !broucher || images.length < 1 || !category) {
      if (anyBlank) {
        toast.info("Enter Product name in all languages");
      } else {
        toast.info("Enter All values ");
      }

      return;
    }
    const formData = new FormData();
    formData.append("title", JSON.stringify(title));
    formData.append("properties", JSON.stringify(propss));
    formData.append("applications", JSON.stringify(apps));
    // formData.append("productImages", images);
    formData.append("specifications", JSON.stringify(specs));
    formData.append("brochure", broucher);
    formData.append("category", category);

    images.forEach((file) => {
      formData.append(`productImages`, file);
    });

    await postProductRequest(formData);
  };

  const clearError = () => {
    setpostProductRequestError(false);
    setmessage("");
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    if (files) {
      const newImages = [];
      const newImagesPreview = [];

      const readFile = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.readyState === 2 && reader.result) {
              resolve({ file, imageUrl: reader.result });
            }
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      Promise.all(Array.from(files).map((file) => readFile(file)))
        .then((results) => {
          results.forEach((result) => {
            newImages.push(result.file);
            newImagesPreview.push(result.imageUrl);
          });

          setImages(newImages);
          setImagesPreview(newImagesPreview);
        })
        .catch((error) => {
          console.error("Error reading files:", error);
        });
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(images);
    const reorderedPreviews = Array.from(imagesPreview);
    const [removedImage] = reorderedImages.splice(result.source.index, 1);
    const [removedPreview] = reorderedPreviews.splice(result.source.index, 1);

    reorderedImages.splice(result.destination.index, 0, removedImage);
    reorderedPreviews.splice(result.destination.index, 0, removedPreview);

    setImages(reorderedImages);
    setImagesPreview(reorderedPreviews);
  };

  useEffect(() => {
    if (postProductRequestError) {
      // {Show toast}
      toast.warn(message || "Product upload fail");
      clearError();
    }

    if (success) {
      toast.success(message || "Product upload successfull ");
      setmessage("");
      setsuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postProductRequestError, success]);

  return (
    <div className="mt-9">
      <div className="w-[95%] mx-auto">
        {/* Product Name  */}
        <div className="product-name ">
          <div className="label-product-title text-center my-7">
            <label className="text-xl underline underline-offset-8 ">
              Product Name
            </label>
          </div>

          <div className="flex flex-wrap gap-4">
            <div>
              <p className="mb-4 font-bold text-xl">English</p>
              <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                <input
                  type="text"
                  className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=""
                  value={title.en}
                  onChange={(e) => {
                    settitle({ ...title, en: e.target.value });
                  }}
                  required
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Product Name
                </label>
              </div>
            </div>

            <div>
              <p className="mb-4 font-bold text-xl">Chineese</p>
              <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                <input
                  type="text"
                  className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=""
                  value={title.ch}
                  onChange={(e) => {
                    settitle({ ...title, ch: e.target.value });
                  }}
                  required
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Product Name
                </label>
              </div>
            </div>

            <div>
              <p className="mb-4 font-bold text-xl">French</p>
              <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                <input
                  type="text"
                  className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=""
                  value={title.fr}
                  onChange={(e) => {
                    settitle({ ...title, fr: e.target.value });
                  }}
                  required
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Product Name
                </label>
              </div>
            </div>

            <div>
              <p className="mb-4 font-bold text-xl">German</p>
              <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                <input
                  type="text"
                  className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=""
                  value={title.ge}
                  onChange={(e) => {
                    settitle({ ...title, ge: e.target.value });
                  }}
                  required
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Product Name
                </label>
              </div>
            </div>

            <div>
              <p className="mb-4 font-bold text-xl">Russian</p>
              <div className="relative  h-10 w-full min-w-[200px] max-w-[24rem] mb-6">
                <input
                  type="text"
                  className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=""
                  value={title.ru}
                  onChange={(e) => {
                    settitle({ ...title, ru: e.target.value });
                  }}
                  required
                />
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Product Name
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Specification  */}
        <div>
          {/* Speceifications List  */}
          <div className="label-product-title text-center my-7">
            <label className="text-xl underline underline-offset-8 ">
              Specifications
            </label>
          </div>
          <div className="specificationList my-7 bg-slate-100 dark:bg-slate-800 p-5">
            <h1 className="font-bold underline text-xl">Specification List</h1>
            <ul
              style={{
                listStyle: "circle",
              }}
            >
              {specs.en.map((s, idx) => {
                return (
                  <li key={idx}>
                    <div className="flex justify-between">
                      <span>{s}</span>{" "}
                      <button
                        onClick={() => {
                          removeSpec(idx);
                        }}
                        className="cursor-pointer hover:underline text-red-500"
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Add Specification */}

          <div className="specifications">
            <div>
              <div>
                <p className="mb-4 font-bold text-xl">English</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={spec.en}
                    onChange={(e) => {
                      setspec({ ...spec, en: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Specification point
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Chineese</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={spec.ch}
                    onChange={(e) => {
                      setspec({ ...spec, ch: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Specification point
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">French</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={spec.fr}
                    onChange={(e) => {
                      setspec({ ...spec, fr: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Specification point
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">German</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={spec.ge}
                    onChange={(e) => {
                      setspec({ ...spec, ge: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Specification point
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Russian</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={spec.ru}
                    onChange={(e) => {
                      setspec({ ...spec, ru: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Specification point
                  </label>
                </div>
              </div>
            </div>

            <button
              onClick={handleSpecificationSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-xl"
            >
              {" "}
              Add Specification{" "}
            </button>
          </div>
        </div>

        {/*Properties  */}
        <div className="my-11">
          <div className="label-product-title text-center my-7">
            <label className="text-xl underline underline-offset-8 ">
              Properties
            </label>
          </div>
          {/* Properties List  */}
          <div className="specificationList my-7  bg-slate-100 dark:bg-slate-800 p-5">
            <h1 className="font-bold underline text-xl">Properties List</h1>
            <ul
              style={{
                listStyle: "circle",
              }}
            >
              {propss.en.map((s, idx) => {
                return (
                  <li key={idx}>
                    <div className="flex justify-between">
                      <span>{s}</span>{" "}
                      <button
                        onClick={() => {
                          removeProp(idx);
                        }}
                        className="cursor-pointer hover:underline text-red-500"
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Add Properties */}

          <div className="specifications">
            <div>
              <div>
                <p className="mb-4 font-bold text-xl">English</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={prop.en}
                    onChange={(e) => {
                      setprop({ ...prop, en: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Property in English
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Chineese</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={prop.ch}
                    onChange={(e) => {
                      setprop({ ...prop, ch: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Property in Chineese
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">French</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={prop.fr}
                    onChange={(e) => {
                      setprop({ ...prop, fr: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Property in French
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">German</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={prop.ge}
                    onChange={(e) => {
                      setprop({ ...prop, ge: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Property in German
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Russian</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={prop.ru}
                    onChange={(e) => {
                      setprop({ ...prop, ru: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Property in Russian
                  </label>
                </div>
              </div>
            </div>

            <button
              onClick={handlePropertiesSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-xl"
            >
              {" "}
              Add Property{" "}
            </button>
          </div>
        </div>

        {/*Applications  */}
        <div className="my-11">
          <div className="label-product-title text-center my-7">
            <label className="text-xl underline underline-offset-8 ">
              Applications
            </label>
          </div>
          {/* Applications List  */}
          <div className="specificationList my-7  bg-slate-100 dark:bg-slate-800 p-5">
            <h1 className="font-bold underline text-xl">Applications List</h1>
            <ul
              style={{
                listStyle: "circle",
              }}
            >
              {apps.en.map((s, idx) => {
                return (
                  <li key={idx}>
                    <div className="flex justify-between">
                      <span>{s}</span>{" "}
                      <button
                        onClick={() => {
                          removeApp(idx);
                        }}
                        className="cursor-pointer hover:underline text-red-500"
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* Add Properties */}

          <div className="specifications">
            <div>
              <div>
                <p className="mb-4 font-bold text-xl">English</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={app.en}
                    onChange={(e) => {
                      setapp({ ...app, en: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Application in English
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Chineese</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={app.ch}
                    onChange={(e) => {
                      setapp({ ...app, ch: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Application in Chineese
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">French</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={app.fr}
                    onChange={(e) => {
                      setapp({ ...app, fr: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Application in French
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">German</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={app.ge}
                    onChange={(e) => {
                      setapp({ ...app, ge: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Application in German
                  </label>
                </div>
              </div>

              <div>
                <p className="mb-4 font-bold text-xl">Russian</p>
                <div className="relative  h-10 w-full min-w-[200px]  mb-6">
                  <input
                    type="text"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=""
                    value={app.ru}
                    onChange={(e) => {
                      setapp({ ...app, ru: e.target.value });
                    }}
                  />
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Application in Russian
                  </label>
                </div>
              </div>
            </div>

            <button
              onClick={handleApplicationsSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-xl"
            >
              {" "}
              Add Application{" "}
            </button>
          </div>
        </div>

        {/* Categories */}

        <div className="h-fit flex justify-end">
          <Select onValueChange={handleCategoryChange}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a category" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Categories</SelectLabel>
                {categories.map((c) => {
                  return (
                    <SelectItem key={c._id} value={c._id}>
                      {c.name.en}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {/* Add product images */}
        <div className="images-container py-7">
          <div className="label-productImages text-center mb-8">
            <label
              htmlFor="productImagesInput"
              className="text-xl underline underline-offset-8"
            >
              Add {"Product's"} Images
            </label>
          </div>
          <input
            type="file"
            name="productImages"
            id=""
            accept="image/*"
            multiple
            onChange={handleFileChange}
          />

          {/* Drag and drop image previews */}
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="imagesPreview" direction="horizontal">
              {(provided) => (
                <div
                  className="flex flex-wrap my-4"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {imagesPreview &&
                    imagesPreview.length > 0 &&
                    imagesPreview.map((image, index) => (
                      <Draggable
                        key={index}
                        draggableId={`image-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mx-1"
                          >
                            <img
                              src={`${image}`}
                              alt={`Preview ${index}`}
                              className="w-20"
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div className="images-container py-7">
          <div className="label-productImages text-center mb-8">
            <label
              htmlFor="productImagesInput"
              className="text-xl underline underline-offset-8"
            >
              Add{" Product's "}Brochure
            </label>
          </div>
          <input
            type="file"
            name="productBrochure"
            id="productBrochureInput"
            accept="image/* ,.pdf"

          
            max={1}
            onChange={handleBrochureFileChange}
          />
        </div>

        {/* Submit button */}
        <div className="add-btn flex justify-center mt-9 mb-11">
          <button
            onClick={handleFormSubmit}
            className="mx-auto bg-[#0285AD] px-8 py-4 rounded-full text-white"
          >
            Add Product
          </button>
        </div>
      </div>
      {message && <div className="toast">{message}</div>}
    </div>
  );
}

export default AddProduct;
