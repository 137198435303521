import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";





import "./carousel.css"; // Import the custom CSS file

function LandingPage() {
  const images = ["https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/sli5gkgppwir07waklw6", "https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/cjhrz7rk8rpccm8nyfyp", "https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/kjertouz1wxvlxs1mxy0","https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/yizbckuec0sakhqw5xgn" , "https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/cu30mqmjqxwlz9uy40ff" ,"https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/cp2mkbc2dwjlxcfrz5pi","https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/layzfdljnq4aqzfh0mzx"];

  return (
    <div className="landing-page - ">

    <Carousel
      autoPlay
      interval={3000}
      showIndicators={true}
      showStatus={false}
      stopOnHover={false}
      infiniteLoop
      showThumbs={false}
      showArrows={false}
      className="carousel-wrapper mt-20"
    >
      {images.map((img, idx) => {
        return (
          <div
            key={idx}
            className=" max-h-[90vh]"
          >
             

            <img
            
              src={img}
              className="h-full w-full object-fill"
              alt="Image 1"
            />
            
          </div>
        );
      })}
    </Carousel>
    </div>

  );
}

export default LandingPage;
