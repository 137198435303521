import useCategories from "@/context/categotiesContext";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function CategoryImage() {
  const { categories, categoriesLoading } = useCategories();

  const [selectedCategory, setselectedCategory] = useState(null);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('categoryid');
  useEffect(() => {
    if (categories && id) {
      const matchingCategory = categories.find(
        (category) =>
          category._id === id
      );
      if (matchingCategory) {
        setselectedCategory(matchingCategory);
        // Assuming the image URL is stored in image.url
      }
    }
  }, [categories, id]);

  if (categoriesLoading) {
    return (
      <div className="h-screen  w-screen flex items-center justify-center text-2xl ">
        Loading....
      </div>
    );
  }

  return (
    <div className="relative image   max-h-[90vh] ">
      {selectedCategory && (
        <>
          <img
          loading="eager"
            src={selectedCategory.coverImage.url}
            alt="about-us img"
            className="h-[98%] w-full"
          />
          {/* <h1 className="absolute font-semibold capitalize italic text-3xl mdd:text-5xl top-[40%] text-white left-[10%] ">
            {" "}
            {selectedCategory.name[language ]}
            <hr className="my-2 mdd:my-7 w-[50%] border-2 border-gray-300 rounded-full " />
          </h1> */}
        </>
      )}
      <div className="links flex justify-end text-xl p-1 pr-5 space-x-1 my-2 lg:text-2xl">
        <Link className="hover:underline hover:text-red-500" to={"/"}>Home</Link> <span className="text-red-800">{" / "}</span>
        <Link className="hover:underline hover:text-red-500" to={`/products?categoryid=${id}`}>Category</Link> 
        
      </div>
    </div>
  );
}

export default CategoryImage;
