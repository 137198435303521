import { createContext, useContext } from "react";

export const categoriesContext = createContext({
  categories: [],
  categoriesLoading: false,
  fetchCategories : async ()=>{},
  
});


export const CategoriesContextProvider = categoriesContext.Provider;

export default function useCategories() {
  return useContext(categoriesContext);
}
