import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
function ContactPage() {
  const { t } = useTranslation();
  // const [agreed, setAgreed] = useState(false)

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [code, setCode] = useState("");
  const [message, setmessage] = useState("");

  const sendMessage = async (formdata) => {
    try {
      toast.loading("Sending message to admin.");
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `/api/v1/messages`;

      const { data } = await axios.post(url, formdata, config);

      if (data.success) {
        toast.dismiss();
        toast.success("Message have been send.");
        setEmail("");
        setfirstName("");
        setlastName("");
        setmessage("");
        setTel("");
        setCode("");
        setmessage("");
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Send message failed ");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", firstName + " " + lastName);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("telephone", tel);
    formData.append("postcode", code);

    await sendMessage(formData);
  };

  return (
    <div className="Contact-us mt-20 bg-[#F6F4F2] dark:bg-gray-800 ">
      {/* Image */}
      <div className="image w-full ">
        <img
          src={
            "https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/cojn4qfyddf3t9ser0hl"
          }
          alt="about-us img"
          className="h-full w-full"
        />
      </div>

      {/* Content */}
      <div className="min-h-screen  mt-24 lg:w-[70%] mx-auto py-16  ">
        {/* Part 1 */}
        <div className="locationbox  ">
          <div className="buttons flex lg:w-[70%] xl:w-[50%]  ">
            <NavLink
              end
              className={({ isActive }) =>
                `${
                  isActive
                    ? "flex-1 text-center p-2  bg-red-800 text-white"
                    : "flex-1 text-center p-2  text-black bg-[#dadada]"
                } flex items-center justify-center  `
              }
              to="/contact-us"
            >
              {t("Head-Office")}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `${
                  isActive
                    ? "flex-1 text-center p-2  bg-red-800 text-white"
                    : "flex-1 text-center p-2  text-black bg-[#dadada]"
                } flex items-center justify-center  `
              }
              to="/contact-us/factory-address"
            >
              {t("Factory-Address")}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `${
                  isActive
                    ? "flex-1 text-center p-2  bg-red-800 text-white"
                    : "flex-1 text-center p-2  text-black bg-[#dadada]"
                } flex items-center justify-center  `
              }
              to="/contact-us/sales-office"
            >
              {t("Sales-Office")}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `${
                  isActive
                    ? "flex-1 text-center p-2  bg-red-800 text-white"
                    : "flex-1 text-center p-2  text-black bg-[#dadada]"
                } flex items-center justify-center   `
              }
              to="/contact-us/location"
            >
              {t("Location")}
            </NavLink>
          </div>
          <div className="bg-white dark:bg-[#020715]  min-h-[30vh] ">
            <Outlet />
          </div>
        </div>

        {/*  Part 2 */}
        <div className="isolate mt-7 mb-16 bg-white dark:bg-[#020715] px-6 py-24 sm:py-32 lg:px-8 ">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
          >
            <div />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-300 sm:text-4xl">
              {t("Send an enquiry")}
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              {t("You can contact us by sending an enquiry.")}
            </p>
          </div>
          <form
            onSubmit={handleFormSubmit}
            className="mx-auto mt-16 max-w-xl sm:mt-20"
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 dark:text-gray-200 text-gray-900"
                >
                 {t("First Name")}
                </label>
                <div className="mt-2.5">
                  <input
                    required
                    placeholder={t("First Name")}
                    id="first-name"
                    name="first-name"
                    type="text"
                    value={firstName}
                    onChange={(e) => {
                      setfirstName(e.target.value);
                    }}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 dark:text-gray-200 text-gray-900"
                >
                  {t("Last Name")}
                </label>
                <div className="mt-2.5">
                  <input
                    required
                    id="last-name"
                    name="last-name"
                    placeholder={t("Last Name")}
                    type="text"
                    value={lastName}
                    onChange={(e) => {
                      setlastName(e.target.value);
                    }}
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="Telephone"
                  className="block text-sm font-semibold leading-6 dark:text-gray-200 text-gray-900"
                >
                  {t("Telephone")}
                </label>
                <div className="mt-2.5">
                  <input
                    required
                    id="Telephone"
                    name="Telephone"
                    placeholder={t("Telephone")}
                    type="tel"
                    value={tel}
                    onChange={(e) => {
                      setTel(e.target.value);
                    }}
                    autoComplete="organization"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 dark:text-gray-200 text-gray-900"
                >
                  {t("Email")}
                </label>
                <div className="mt-2.5">
                  <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    placeholder={t("Email")}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="code"
                  className="block text-sm font-semibold leading-6 dark:text-gray-200 text-gray-900"
                >
                  {t("Post Code")}
                </label>
                <div className="mt-2.5">
                  <input
                    required
                    id="code"
                    name="code"
                    type="text"
                    placeholder={t("Post Code")}
                    value={code}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 dark:text-gray-200 text-gray-900"
                >
                {t("Message")}
                </label>
                <div className="mt-2.5">
                  <textarea
                    required
                    value={message}
                    placeholder={t("Message")}
                    onChange={(e) => {
                      setmessage(e.target.value);
                    }}
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="block w-full rounded-md bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {t("Send")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
