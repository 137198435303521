// import { Facebook, Instagram, Twitter } from "lucide-react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { t } from "i18next";
function Footer() {
  const [email, setemail] = useState("");
  

  const sendMessage = async (formdata) => {
    try {
      toast.loading("Sending message to admin.");
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const url = `/api/v1/messages`;

      const { data } = await axios.post(url, formdata, config);

      if (data.success) {
        toast.dismiss();
        toast.success("Message have been send.");
        setemail("");
    
      }
    } catch (error) {
      console.log(error)
      toast.dismiss();
      toast.error(error.response.data.message);
    }
  };




  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", "Subscriber");
    formData.append("email", email);
  formData.append("message","Subsribed")


    await sendMessage(formData);
    
  };

  return (
    <div className="footer ">
      <div className="newsettler  bg-slate-200 dark:bg-slate-800  flex flex-col items-center text-center gap-5 py-11">
        {/* Text */}
        <div className="text ">
          <h1 className="text-black font-semibold text-xl dark:text-white">
            {t("Newsletter Subscription")}
          </h1>
          <p className="text-slate-600 dark:text-slate-400">
            {t("Want more? Our monthly newsletter...")}
          </p>
        </div>
        {/* Text field */}
        <form
          onSubmit={handleFormSubmit}
          className="input w-[70%] mdd:w-[40%] mx-auto space-y-3 mdd:space-y-0 mdd:space-x-4 mdd:flex justify-center  items-center "
        >
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setemail(e.target.value);
            }}
            required
            placeholder="Email Address"
            className="bg-gray-400 p-4 placeholder:text-white  text-lg text-white w-full mdd:w-[80%] font-semibold rounded-xl  "
          />

          <button
            type="submit"
            
            className="bg-red-700  rounded-xl text-white p-4"
          >
            {t("Subscribe")}
          </button>
        </form>
      </div>

      {/* <div className=" bg-slate-800  text-white p-5 mdd:flex justify-between items-center  ">
        <div className="options flex-1 text-bold flex flex-col gap-4 text-center underline mdd:flex-row">
          <a href="/"> {t("Privacy Policy")} </a>
          <a href="/">{t("Terms & Conditions")}</a>
          <a href="/">{t("Sitemap")} </a>
        </div>

        <div className="desc flex-1  my-5 mdd:w-[40%] mx-auto  ">
         {t("footer-desc")}
        </div>

        <div className="icons flex-1 justify-center  flex w-fit mx-auto mb-4  mt-7 gap-5">
          <a href="/" className="hover:bg-red-700 p-3 rounded-xl">
            <Instagram />
          </a>
          <a href="/" className="hover:bg-red-700 p-3 rounded-xl">
            <Facebook />
          </a>
          <a href="/" className="hover:bg-red-700 p-3 rounded-xl">
            <Twitter />
          </a>
        </div>
      </div> */}
    </div>
  );
}

export default Footer;
