import { Skeleton } from "@/components/ui/skeleton";
import useCategories from "@/context/categotiesContext";
import useMyLanguage from "@/context/languageContext";

import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { t } from "i18next";
function ProductsPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("categoryid");
  const keyword = query.get("keyword");
  const { language } = useMyLanguage();

  const { categories, categoriesLoading } = useCategories();
  const [products, setproducts] = useState([]);

  const [loading, setloading] = useState(false);
  const fetchProducts = async () => {
    try {
      setloading(true);
      const url = `/api/v1/products${
        "?" + `${id ? "categoryId=" + id : ""}`
      }&language=${language}&keyword=${keyword || ""}`;

      const response = await fetch(url);

      const data = await response.json();
      setproducts(data.products);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };


  
  useGSAP(() => {
    if(!categoriesLoading ){
     if (window.innerWidth < 768) {
       const cards = document.querySelectorAll(".category-products-cards");
       cards.forEach((card) => {
         gsap.from(card, {
           y: "20%",
           x: "20",
           opacity: 0,
           scrollTrigger: {
             trigger: card,
             scroller: "body",
             start: "top 80%",
             end: "top top",
          
           },
         });
       });
     } else {
       gsap.from(".category-products-cards", {
         x: "100%",
         y: "20%",
         opacity: 0,
         stagger: 0.2,
         duration: 0.7,
         scrollTrigger: {
           scroller: "body",
           trigger: "#products-of-category",
           start: "top 55%",
          
         }, 
       });
     }
    }
   },[loading]);
 
  


  useEffect(() => {
    fetchProducts();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [categories, id, keyword]);

  if (categoriesLoading || loading) {
    return (
      <>
        <div className="mt-20 p-12 flex flex-wrap justify-center gap-5   ">
         
          <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
            <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
            <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
          </div>
          <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
            <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
            <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
          </div> <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
            <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
            <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
          </div> <div className="min-h-[420px] w-full  sm:w-[250px] md:w-[300px]  flex-col flex justify-between ">
            <Skeleton className="relative featured-product-c h-[10%]     justify-center "></Skeleton>
            <Skeleton className="relative featured-product-c h-[88%]   sm:w-[250px] md:w-[300px]   justify-center "></Skeleton>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="mt-20">
      {/* Cover Image */}

      {id && (
        <div className="min-h-[25vh]  md:min-h-[40vh] mdd:min-h-[50vh] lg:min-h-[60vh]  ">
          <Outlet />
        </div>
      )}

      <div id="products-of-category" className="products-of-category min-h-[50vh] h-fit    ">
        {keyword ? (
          <h1 className="text-center bg-slate-300  dark:bg-slate-700 text-slate-600 py-4 text-4xl font-bold uppercase my-8 dark:text-slate-100 shadow-xl ">
            {t("Products")}
          </h1>
        ) : (
          <h1 className="text-center bg-slate-300  dark:bg-slate-700 text-slate-600 py-4 text-4xl font-bold uppercase my-8 dark:text-slate-100 shadow-xl ">
            {t("Category")} {t("Products")}
          </h1>
        )}
        {products && (
          <>
            {products.length === 0 ? (
              <div className="h-full  flex justify-center items-center text-3xl  ">
                No Product Found
              </div>
            ) : (
              <>
                {/* Products */}

                <div className="mt-6 p-4 flex flex-wrap justify-center space-x-2 ">
                  {products.map((p) => {
                    return (
                      <div
                      onMouseEnter={() => {
                        const img1 = document.getElementById(`img1-${p._id}`);
                        const img2 = document.getElementById(`img2-${p._id}`);
                    
                        if (img1 && img2) {
                          img1.style.opacity = "0"; // Full opacity
                          img2.style.opacity = "1"; // Hidden (0 opacity)
                        }
                      }}
                      onMouseLeave={() => {
                        const img1 = document.getElementById(`img1-${p._id}`);
                        const img2 = document.getElementById(`img2-${p._id}`);
                    
                        if (img1 && img2) {
                          img1.style.opacity = "1"; // Full opacity
                          img2.style.opacity = "0"; // Hidden (0 opacity)
                        }
                      }}
                      className="relative category-products-cards shadow-lg shadow-black/75 dark:shadow-slate-800/75 my-4 w-full max-w-[430px] sm:w-[400px] md:w-[380px] min-h-[530px] group border-2 border-[#dadada] dark:border-slate-800 rounded-lg overflow-hidden flex flex-col"
                      key={p._id}
                    >
                      {/* Upper Part */}
                      <div className="pl-8 text-white top-0 left-0 upper-part-category bg-black/70 z-10 w-full text-lg font-bold py-4 uppercase">
                        {p.title[language]}
                      </div>
                    
                      {/* Image Container */}
                      <div className="relative flex-grow flex items-center justify-center overflow-hidden">
                        <img
                          src={p.images[0].url}
                          alt={p.title[language]}
                          id={`img1-${p._id}`}
                          className="absolute transition-all duration-500 w-full h-full object-cover"
                        />
                        <img
                          src={p.images[0].url}
                          alt={p.title[language]}
                          id={`img2-${p._id}`}
                          className="absolute transition-all duration-500 opacity-0 scale-125 w-full h-full object-cover brightness-75 dark:brightness-50"
                        />
                      </div>
                    
                      {/* Lower Part */}
                      <Link
                        to={`/products/details?categoryid=${p.category._id}&p_id=${p._id}`}
                        className="text-white down-part-category cursor-pointer gap-2 hover:gap-3 transition-all duration-200 flex bottom-0 left-0 text-lg bg-black/70 z-10 w-full pl-8 py-4"
                      >
                        <div className="underline">{t("View-Product")}</div>{" "}
                        <div className="!no-underline">{"->"}</div>
                      </Link>
                    </div>
                    
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ProductsPage;
// 1920 x 823
