import { createContext, useContext } from "react";



export const languagesContext = createContext({
  language: 'en',            
  changeLanguage: () => {}, 
});


export const LanguagesContextProvider = languagesContext.Provider;


export default function useMyLanguage() {
  return useContext(languagesContext);
}
