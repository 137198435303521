import useMyLanguage from "@/context/languageContext";
import useProductDetails from "@/context/productDetailsContext";
import "./outlets.css"
import { t } from "i18next";
function Applications() {
  const { product, loading } = useProductDetails();
  const { language } = useMyLanguage();


  return (
    <>
      {!loading && (
        <div id="Application-box-details" className="px-12 py-9 space-y-6">
          {product.applications[language].length === 0 ? (
            <div className="pl-6 text-xl">
              {t("No")} {t("Applications")}</div>
          ) : (
            <ul className="list-disc">
              {product.applications[language].map((s, index) => (
                <li key={index}>{s}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
}

export default Applications;
