import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ModeToggle } from "@/components/mode-toggle";
import { useTheme } from "@/components/theme-provider";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import useCategories from "../../../context/categotiesContext";
import "./navbar.css";
import { Input } from "@/components/ui/input";
import WorldFlag from "react-world-flags";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import useMyLanguage from "@/context/languageContext";
import useMe from "@/context/meContext";
import { toast } from "react-toastify";
import axios from "axios";

function NavBar() {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();
  const [isNavBarOpen, setNavBarOpen] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("Language");
  const { categories, categoriesLoading } = useCategories();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { isAuthenticated, fetchMe } = useMe();
  const languages = [
    { value: "en", label: "English", flag: "GB" },
    { value: "ge", label: "German", flag: "DE" },
    { value: "fr", label: "French", flag: "FR" },
    { value: "ch", label: "Chinese", flag: "CN" },
    { value: "ru", label: "Russian", flag: "RU" },
  ];

  const handleMenuBtn = () => {
    setNavBarOpen((prevIsNavBarOpen) => !prevIsNavBarOpen);
  };

  const handleLogout = async () => {
    try {
      toast.loading("Logging out...");
      const url = `/api/v1/logout`; // Adjust URL based on your backend endpoint for logout

      await axios.post(url); // Assuming logout endpoint doesn't require any payload

      // Perform any necessary cleanup or state resets here
      setSuccess(false);
      setError(false);
      toast.dismiss();
      toast.success("Logout Successful");
      await fetchMe();

      navigate("/"); // Redirect to login page after logout
    } catch (error) {
      setError(true);
      toast.dismiss();
      
      toast.error(error.response.data.message || "Logout failed");
    }
  };
  const handleNavigation = (id) => {
    if (window.location.pathname === "/") {
      // If already on homepage, scroll to #services
      const servicesElement = document.getElementById(id);
      servicesElement?.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");

      setTimeout(() => {
        const servicesElement = document.getElementById(id);
        if (servicesElement) {
          servicesElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  const { changeLanguage, language } = useMyLanguage();
  const handleLanguageChange = (ln) => {
    setSelectedLanguage(ln);
    i18n.changeLanguage(ln);
    changeLanguage(ln);
    console.log(ln);
  };

  const handleProductSearch = (event) => {
    event.preventDefault();
    const val = searchValue;
    setsearchValue("");

    navigate(`/products?keyword=${val}`);
  };
  useEffect(() => {
    let lastScrollTop = 0;
    const navbar = document.getElementById("nav-bar");
    const threshold = 70;
    let scrollDiff = 0;

    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollChange = scrollTop - lastScrollTop;

      // Accumulate the scroll difference
      scrollDiff += scrollChange;

      // If scrolled more than the threshold, hide or show the navbar
      if (scrollDiff > threshold) {
        navbar.style.top = "-100%";
        scrollDiff = 0; // Reset scroll difference after hiding
      } else if (scrollDiff < -threshold) {
        navbar.style.top = "0%";
        scrollDiff = 0; // Reset scroll difference after showing
      }

      lastScrollTop = scrollTop;
    };

    if (window.innerWidth > 880) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {}, [success, error, isAuthenticated]);
  const logoUrl =
    theme === "dark"
      ? "https://res.cloudinary.com/dvobpdvef/image/upload/f_auto,q_auto/v1/Important%20Data/xbkpon6ususi6hwhcyhq"
      : "https://res.cloudinary.com/dvobpdvef/image/upload/f_auto,q_auto/v1/Important%20Data/jezix5rufvtn0rwdkanb";

  const selectedLangData = languages.find(
    (lang) => lang.value === selectedLanguage
  );
  return (
    <div
      style={{
        // boxShadow: " rgba(105, 10, 176, 0.7) 0px 0px 15px"
        boxShadow:
          " rgba(0, 0, 0, 0.5) 100px 700px 300px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
      }}
      id="nav-bar"
      className={`fixed z-50  h-[80px] items-center   flex top-0 left-0  lg:px-12 max-w-screen w-screen  nav-bar justify-between   px-4 py-4 bg-white dark:bg-[#020715] `}
    >
      <button
        onClick={() => {
          handleNavigation("homePage");
        }}
        className="text-white cursor-pointer  logo  font-bold text-3xl"
      >
        <img src={logoUrl} alt="" className="w-[60px] " />
      </button>

      {/* Menu Button */}
      <div className={`btn  z-30 flex justify-center items-center gap-3  `}>
        <div className="mdd:hidden">
          <ModeToggle />
        </div>
        <div
          onClick={handleMenuBtn}
          className={` open-menu-button  cursor-pointer mdd:hidden w-[30px] flex flex-col gap-2 ${
            isNavBarOpen ? "hidden" : "flex"
          } `}
        >
          <div className="linee  border-t-[3px] dark:border-white border-black "></div>
          <div className="linee  border-t-[3px] dark:border-white border-black "></div>
          <div className="linee  border-t-[3px] dark:border-white border-black "></div>
        </div>

        <div
          onClick={handleMenuBtn}
          className={`close-menu-button cross-container  h-8 flex justify-center items-center cursor-pointer mdd:hidden w-[30px]  ${
            isNavBarOpen ? "block" : "hidden"
          }   mdd:hidden w-[15px] `}
        >
          <div className="line line1 dark:bg-white bg-black"></div>
          <div className="line line2 dark:bg-white bg-black"></div>
        </div>
      </div>
      {/* MObile Navbar */}
      <div
        className={`mobile-nav-menu px-7  left-0 w-full absolute  mdd:hidden pt-12 pb-24   
          dark:bg-[#020715] bg-white  border-zinc-100 min-h-fit transform transition-transform duration-500 ${
            isNavBarOpen
              ? " translate-y-0 !top-0"
              : "bottom-[100%] -translate-y-[100%]"
          } mdd:hidden overflow-hidden flex flex-col text-xl border-t-[1px]  w-full gap-2 font-semibold`}
      >
        <Link
          to="/"
          className=""
          onClick={() => {
            setNavBarOpen(false);
          }}
        >
          {"->"} {t("home")}
        </Link>
        <button
          className=" w-fit"
          onClick={() => {
            handleNavigation("categories");
            handleMenuBtn();
          }}
        >
          {"->"} {t("products")}
        </button>
        <Link
          to="/about-us"
          className=" "
          onClick={() => {
            setNavBarOpen(false);
          }}
        >
          {"->"} {t("about-us")}
        </Link>
        <Link
          to="/contact-us"
          className=""
          onClick={() => {
            setNavBarOpen(false);
          }}
        >
          {"->"} {t("contact-us")}
        </Link>
        {isAuthenticated && (
          <>
            <Link
              to="/admin/dashboard"
              className=""
              onClick={() => {
                setNavBarOpen(false);
              }}
            >
              {"->"} Admin Dashboard
            </Link>
            <button
              className=" w-fit"
              onClick={() => {
                setNavBarOpen(false);
                handleLogout();
              }}
            >
              {"->"} Logout
            </button>
          </>
        )}
        <div className="w-[70%] mx-auto  flex my-4">
          <form
            className="flex w-full gap-3"
            onSubmit={(e) => {
              handleProductSearch(e);
            }}
          >
            <Input
              value={searchValue}
              onChange={(e) => {
                setsearchValue(e.target.value);
              }}
              placeholder={t("search-products-place-holder")}
            />
            <Button type="submit">Search</Button>
          </form>
        </div>
        <Select onValueChange={handleLanguageChange}>
          <SelectTrigger className="w-[200px]">
            <SelectValue
              placeholder={
                <div className="flex items-center">
                  <WorldFlag code={"GB"} className="w-5 h-5 mr-2" />
                  {"English"}
                </div>
              }
            >
              {selectedLangData ? (
                <div className="flex items-center">
                  <WorldFlag
                    code={selectedLangData.flag}
                    className="w-5 h-5 mr-2"
                  />
                  {selectedLangData.label}
                </div>
              ) : (
                "Language"
              )}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {languages.map((lang) => (
              <SelectItem key={lang.value} value={lang.value}>
                <div className="flex items-center">
                  <WorldFlag code={lang.flag} className="w-5 h-5 mr-2" />
                  {lang.label}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Desktop options */}
      {/* <div className="absolute desktop-options mdd:flex top-[50%] -translate-y-[50%] left-[50%] w-fit -translate-x-[50%] hidden "> */}
      <div className=" desktop-options mdd:flex tw-fit hidden ">
        <NavigationMenu>
          <NavigationMenuList>
            {/* Home */}
            <NavigationMenuItem className="relative">
              <Link to="/" className="w-32  hover:text-red-400">
                {t("home")}
              </Link>
            </NavigationMenuItem>

            {/* Products */}
            <NavigationMenuItem className="relative">
              <NavigationMenuTrigger className="hover:text-red-500">
                {t("products")}
              </NavigationMenuTrigger>
              <NavigationMenuContent className="absolute left-0  shadow-lg">
                <div
                  className="flex flex-col gap-2 w-52"
                  style={{ padding: "20px 10px" }}
                >
                  {!categoriesLoading &&
                    categories.map(
                      (c) => (
                        <Link
                          key={c._id}
                          to={`/products?categoryid=${c._id}`}
                          className="hover:text-red-500"
                        >
                          {c.name[language]}
                        </Link>
                      )
                    )}
                </div>
              </NavigationMenuContent>
            </NavigationMenuItem>

            {/* About Us */}
            <NavigationMenuItem className="relative">
              <Link to="/about-us" className="w-32 hover:text-red-400">
                {t("about-us")}
              </Link>
            </NavigationMenuItem>

            {/* Contact Us */}
            <NavigationMenuItem className="relative ">
              <Link to="/contact-us" className="w-32 mx-4 hover:text-red-400">
                {t("contact-us")}
              </Link>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </div>

      {/* Part 2 */}

      {/* <div className="absolute desktop-options gap-2  top-[50%] hidden mdd:flex -translate-y-[50%] right-0 -translate-x-[20%] h-[40px] w-fit "> */}
      <div className=" desktop-options gap-2  ] hidden mdd:flex  w-fit ">
        <form
          onSubmit={(e) => {
            handleProductSearch(e);
          }}
        >
          {" "}
          <Input
            value={searchValue}
            onChange={(e) => {
              setsearchValue(e.target.value);
            }}
            placeholder={t("search-products-place-holder")}
          />
        </form>

        <Select onValueChange={handleLanguageChange}>
          <SelectTrigger className="w-[200px]">
            <SelectValue
              placeholder={
                <div className="flex items-center">
                  <WorldFlag code={"GB"} className="w-5 h-5 mr-2" />
                  {"English"}
                </div>
              }
            >
              {selectedLangData ? (
                <div className="flex items-center">
                  <WorldFlag
                    code={selectedLangData.flag}
                    className="w-5 h-5 mr-2"
                  />
                  {selectedLangData.label}
                </div>
              ) : (
                "Language"
              )}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {languages.map((lang) => (
              <SelectItem key={lang.value} value={lang.value}>
                <div className="flex items-center">
                  <WorldFlag code={lang.flag} className="w-5 h-5 mr-2" />
                  {lang.label}
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div>
          <ModeToggle />
        </div>
        {isAuthenticated && (
          <button
            className={` logout inline-flex  justify-center items-center ml-2 -mr-4 space-x-3 h-12 `}
          >
            {/* Dashboard button */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={() => {
                navigate("/admin/dashboard");
              }}
              className="lucide lucide-layout-dashboard"
            >
              <rect width="7" height="9" x="3" y="3" rx="1" />
              <rect width="7" height="5" x="14" y="3" rx="1" />
              <rect width="7" height="9" x="14" y="12" rx="1" />
              <rect width="7" height="5" x="3" y="16" rx="1" />
            </svg>

            {/* Logout button */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
               width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-log-out"
              onClick={handleLogout}
            >
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
              <polyline points="16 17 21 12 16 7" />
              <line x1="21" x2="9" y1="12" y2="12" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

{
  // {isAuthenticated && (
  //     <button
  //       className={` logout flex justify-center items-center ml-2 -mr-4 space-x-3`}
  //     >
  //       {/* Dashboard button */}
  //       <svg
  //         onClick={() => {
  //           navigate("/admin/dashboard");
  //         }}
  //         width="100%"
  //         height="100%"
  //         viewBox="0 -0.5 25 25"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //         stroke="#000000"
  //       >
  //         <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
  //         <g
  //           id="SVGRepo_tracerCarrier"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //         ></g>
  //         <g id="SVGRepo_iconCarrier">
  //           {" "}
  //           <path
  //             fillRule="evenodd"
  //             clipRule="evenodd"
  //             d="M9.918 10.0005H7.082C6.66587 9.99708 6.26541 10.1591 5.96873 10.4509C5.67204 10.7427 5.50343 11.1404 5.5 11.5565V17.4455C5.5077 18.3117 6.21584 19.0078 7.082 19.0005H9.918C10.3341 19.004 10.7346 18.842 11.0313 18.5502C11.328 18.2584 11.4966 17.8607 11.5 17.4445V11.5565C11.4966 11.1404 11.328 10.7427 11.0313 10.4509C10.7346 10.1591 10.3341 9.99708 9.918 10.0005Z"
  //             stroke="#ffffff"
  //             strokeWidth="1.5"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           ></path>{" "}
  //           <path
  //             fillRule="evenodd"
  //             clipRule="evenodd"
  //             d="M9.918 4.0006H7.082C6.23326 3.97706 5.52559 4.64492 5.5 5.4936V6.5076C5.52559 7.35629 6.23326 8.02415 7.082 8.0006H9.918C10.7667 8.02415 11.4744 7.35629 11.5 6.5076V5.4936C11.4744 4.64492 10.7667 3.97706 9.918 4.0006Z"
  //             stroke="#ffffff"
  //             strokeWidth="1.5"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           ></path>{" "}
  //           <path
  //             fillRule="evenodd"
  //             clipRule="evenodd"
  //             d="M15.082 13.0007H17.917C18.3333 13.0044 18.734 12.8425 19.0309 12.5507C19.3278 12.2588 19.4966 11.861 19.5 11.4447V5.55666C19.4966 5.14054 19.328 4.74282 19.0313 4.45101C18.7346 4.1592 18.3341 3.9972 17.918 4.00066H15.082C14.6659 3.9972 14.2654 4.1592 13.9687 4.45101C13.672 4.74282 13.5034 5.14054 13.5 5.55666V11.4447C13.5034 11.8608 13.672 12.2585 13.9687 12.5503C14.2654 12.8421 14.6659 13.0041 15.082 13.0007Z"
  //             stroke="#ffffff"
  //             strokeWidth="1.5"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           ></path>{" "}
  //           <path
  //             fillRule="evenodd"
  //             clipRule="evenodd"
  //             d="M15.082 19.0006H17.917C18.7661 19.0247 19.4744 18.3567 19.5 17.5076V16.4936C19.4744 15.6449 18.7667 14.9771 17.918 15.0006H15.082C14.2333 14.9771 13.5256 15.6449 13.5 16.4936V17.5066C13.525 18.3557 14.2329 19.0241 15.082 19.0006Z"
  //             stroke="#ffffff"
  //             strokeWidth="1.5"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           ></path>{" "}
  //         </g>
  //       </svg>
  //       {/* Logout button */}
  //       <svg
  //         onClick={handleLogout}
  //         width="100%"
  //         height="90%"
  //         viewBox="0 0 24.00 24.00"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //         stroke="#ffffff"
  //       >
  //         <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
  //         <g
  //           id="SVGRepo_tracerCarrier"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           stroke="#ffffff"
  //           strokeWidth="0.048"
  //         ></g>
  //         <g id="SVGRepo_iconCarrier">
  //           <path
  //             d="M21 12L13 12"
  //             stroke="#ffffff"
  //             strokeWidth="2"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           ></path>
  //           <path
  //             d="M18 15L20.913 12.087V12.087C20.961 12.039 20.961 11.961 20.913 11.913V11.913L18 9"
  //             stroke="#ffffff"
  //             strokeWidth="2"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           ></path>
  //           <path
  //             d="M16 5V4.5V4.5C16 3.67157 15.3284 3 14.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14.5C15.3284 21 16 20.3284 16 19.5V19.5V19"
  //             stroke="#ffffff"
  //             strokeWidth="2"
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //           ></path>
  //         </g>
  //       </svg>
  //     </button>
  //   )}
}
export default NavBar;
