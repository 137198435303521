import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./assets/languages/en/language.json";
import germen from "./assets/languages/ge/language.json";
import french from "./assets/languages/fr/language.json";
import chineese from "./assets/languages/ch/language.json";
import russian from "./assets/languages/ru/language.json";

const resources = {
  en: {
    translation: english,
  },
  ge: {
    translation: germen,
  },
  fr: {
    translation: french,
  },
  ch: {
    translation: chineese,
  },
  ru: {
    translation: russian,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
