import { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

function AdminCategories() {
  useEffect(() => {
    if (window.innerWidth < 756) {
      const element = document.getElementById("products-section");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div className="admin-products">
      <div className="products-heading-and-add-btn flex justify-around text-lg mt-2">
        <Link
          to={"/admin/dashboard/categories"}
          className="  text-center  pr-3 w-1/2 py-2 shadow-xl rounded-xl hover:bg-slate-200 dark:bg-slate-600 dark:hover:bg-slate-700"
        >
          Categories
        </Link>

        <Link
          to={"/admin/dashboard/categories/upload"}
          className="w-[48%] py-2 text-center shadow-xl rounded-xl  hover:bg-slate-200 dark:bg-slate-600 dark:hover:bg-slate-700 "
        >
          {" "}
          Add Category
        </Link>
      </div>

      <div id="products-section" className="products-section">
        <Outlet />
      </div>
    </div>
  );
}

export default AdminCategories;
