import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
function AboutUs() {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(useGSAP);

  const { t } = useTranslation();

  let container;
  useGSAP(
    () => {
      gsap.to(".animated-up-line-about", {
        scrollTrigger: {
          scroller: "body",
          trigger: "#about-us",
          scrub: true,

          end: "bottom 50%",
          start: "top 60%",
        },
        y: "100%",
      });

      if (window.innerWidth < 768) {
        gsap.from("#section1_about", {
          x: -100,
          duration: 0.7,
          opacity: 0,
          scrollTrigger: {
            scroller: "body",
            trigger: "#about-us",

            start: "top 80%",
          },
        });
        gsap.from(".about-us-description", {
          y: 100,
          duration: 0.7,
          opacity: 0,
          stagger: 0.3,
          scrollTrigger: {
            scroller: "body",
            trigger: "#section1_about",

            start: "top 27%",
          },
        });
      } else {
        gsap.from("#section1_about", {
          y: -100,
          duration: 1,
          opacity: 0,
          scale: 1.3,
          scrollTrigger: {
            scroller: "body",
            trigger: "#about-us",

            start: "top 70%",
          },
        });
        gsap.from(".about-us-description", {
          y: 100,
          duration: 0.7,
          opacity: 0,
          stagger: 0.3,
          scrollTrigger: {
            scroller: "body",
            trigger: "#section1_about",

            start: "top 27%",
          },
        });
      }
    },
    { scope: container }
  );

  return (
    <div
      id="about-us"
      className="about-us relative mb-20  w-full py-10 lg:py-24 min-h-fit"
    >
      <div className="about-us-container  w-[95%] lg:w-[80%]  mx-auto px-1 md:px-5 justify-center    md:flex">
        {/* section  1 */}
        <div
          id="section1_about"
          className="section1_about overflow-hidden rounded-xl  max-h-[400px]   w-[95%] md:w-[40%] mx-auto " 
          style={{
            // boxShadow: " rgba(105, 10, 176, 0.7) 0px 0px 15px"
            boxShadow:
              " rgba(0, 0, 0, 0.5) 10px 7px 30px, rgba(0, 0, 0, 0.27) 0px 10px 10px",
          }}
        >
          <img
            src={
              "https://res.cloudinary.com/db8alopxr/image/upload/f_auto,q_auto/v1/Static%20important/hjveoqajfuorknitmsut"
            }
            alt="About_us Pic"
            className="w-full  md:h-full bg-slate-900 p-5 "
          />
        </div>

        {/* Section 2 */}
        <div className="section2_about text-start pl-7 md:px-16 lg:px-0 flex justify-center items-start flex-col py-4 text-ellipsis md:w-1/2 ">
          <h2
            id="about-us-heading"
            className="font-semibold text-3xl font-sans  lg:text-4xl"
          >
            {t("about-us")}
          </h2>
          <div className="about-us-description-container text-[18px] lg:text-[22px] ">
            <p className="about-us-description pt-4 pb-2">
              {t("about-us-desc")}
            </p>
          </div>
          <Link to={"/about-us"} className="my-4 py-4 px-9 bg-slate-700 text-white rounded-xl hover:!bg-red-950 hover:scale-[1.05] font-bold  " style={{
            background : "linear-gradient(to right,#334155 , rgb(234, 98, 98))"
          }}>{t("Learn-more")}</Link>
        </div>
      </div>

      {/* Animations Line 1  */}
      <div
        className="animated-down-line2 absolute top-0 left-0 overflow-hidden mx-1 md:mx-10 bg-slate-400  w-[3px] md:w-[5px]   "
        id="animation-for-aboutus"
        style={{
          borderRadius: "70%",

          height: "100%",

          boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
        }}
      >
        <div
          id="animated-up-line-about"
          className={`animated-up-line-about w-full h-full absolute  bg-[#242424] `}
        ></div>
      </div>
      {/* Animations Line 2  */}
      <div
        className="animated-down-line2 absolute top-0 right-0 overflow-hidden mx-1 md:mx-10 bg-slate-400 w-[3px] md:w-[5px]  "
        id="animation-for-aboutus"
        style={{
          borderRadius: "70%",

          height: "100%",

          boxShadow: "rgba(105, 10, 176, 0.3) 0px 0px 5px",
        }}
      >
        <div
          className={`animated-up-line-about w-full h-full absolute  bg-[#242424] `}
          id="animated-up-line-about"
        ></div>
      </div>

      
    </div>
  );
}

export default AboutUs;
